







































































import Vue from "vue"
import store from "@/store";
import { getModule } from "vuex-module-decorators";
import PageTitleHeader from "@/components/shared/PageTitleHeader.vue";
import AttendeeCard from "@/components/shared/AttendeeCard.vue";
import Spinners from "@/components/utilities/Spinners.vue";
import attendeeSearchVuexModule from "@/store/vuex-modules/searchAttendeeData";

import { AttendeeSearchResult } from "@/types/interfaces";

const attendeeSearchStore = getModule(attendeeSearchVuexModule);

export default Vue.extend ({
    data() {
        return {

        }
    },
    components: {
        PageTitleHeader,
        AttendeeCard,
        Spinners
    },
    props:{
        id: {
            type: String,
            default: ""
        }
    },
    computed: {

        companyResults() {
            if (attendeeSearchStore.currentCompanyResults) {
                return attendeeSearchStore.currentCompanyResults;
            } else {
                return [];
            }
        },

        textHeader() {
            return store.getters.getPageOptions("attendeeResults")?.textHeader
                ? store.getters.getPageOptions("attendeeResults").textHeader
                : "Attendee Search Results";
        },

        isLoading() {
            return !attendeeSearchStore.isCompanyDataLoaded;
        }
    },


    /**
     * Lifecycle hooks
     */
    mounted() {
        if (this.id) {
            attendeeSearchStore.getCompanyResults(this.id);
        }
    },
    methods: {
    /**
     * Methods
     */
        getInitials(result: AttendeeSearchResult) {
            const returnValue = [];
            if (!result) return "";

            if (result?.firstName) {
                returnValue.push(result.firstName.charAt(0));
            }
            if (result?.lastName) {
                returnValue.push(result.lastName.charAt(0));
            }
            return returnValue.join("");
        },

        goToSearch() {
            this.$router.push("/attendees");
        }
    }
})
